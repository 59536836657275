// import { theme } from "@chakra-ui/react"
import theme from "@chakra-ui/theme"

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      50: "#e0f4ff",
      100: "#b8dbfa",
      200: "#8ec2f1",
      300: "#63aae8",
      400: "#3991e0",
      500: "#1f78c6",
      600: "#135d9b",
      700: "#084370",
      800: "#002846",
      900: "#000e1d",
    },
  },
  fontSizes: {
    ...theme.fontSizes,
    xs: "0.64rem",
    sm: "0.8rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.563rem",
    "2xl": "1.953rem",
    "3xl": "2.441rem",
    "4xl": "3.052rem",
    "5xl": "3.815rem",
    "6xl": "4.768rem",
    "7xl": "5.96rem",
    "8xl": "7.451rem",
    "9xl": "9.313rem",
  },
  styles: {
    ...theme.styles,
    global: props => ({
      "html": {
        fontSize: {
          base: "16px"
        }
      },
      "body": {
        backgroundColor: "gray.200",
        margin: "0",
        padding: "0",
        fontFamily: "open sans, Sans-Serif",
        fontWeight: "normal",
        fontSize: "md",
        lineHeight: "1.75"
      },
      a: {
        color: "brand.400",
        textDecoration: "none",
      },
      h1: {
        fontSize: "3xl",
      },
      h2: {
        fontSize: "2xl",
      },
      h3: {
        fontSize: "xl",
      },
      h4: {
        fontSize: "lg",
      },
    }),
  },
}

export default customTheme
